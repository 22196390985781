<template>
  <div class="d-flex align-items-center">
    <base-button @click="$router.back()" :back="true">
      <horizontal-arrow />
    </base-button>
    <h1 class="m-0 ms-3">{{ transactionInformation.appointment_code }}</h1>
  </div>
  <div class="transactionSinglePage">
    <div class="row line-bottom">
      <div class="col-lg-3">
        <div class="transactionSinglePage__title">{{ $t("Employee") }}</div>
        <div
          class="transactionSinglePage__value transactionSinglePage__value-think"
        >
          <div>
            {{
              `${transactionInformation.employee_first_name} ${transactionInformation.employee_last_name}`
            }}
          </div>
          <div>{{ transactionInformation.expert_code }}</div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="transactionSinglePage__title">
          {{ $t("transactionDate") }}
        </div>
        <div
          class="transactionSinglePage__value transactionSinglePage__value-think"
        >
          <div>
            <data-formatted
              date-format="HH:mm"
              :data="transactionInformation.payment_date"
            />
          </div>
          <div>
            <data-formatted
              date-format="dd MMM yyyy"
              :data="transactionInformation.payment_date"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="transactionSinglePage__title">
          {{ $t("totalPaidEmployee") }}
        </div>
        <div class="transactionSinglePage__value">
          €{{ transactionInformation.employee_price }}
        </div>
      </div>
      <div class="col-lg-3">
        <div class="transactionSinglePage__title">{{ $t("Status") }}</div>
        <div class="transactionSinglePage__value">
          {{ transactionInformation.status }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 mt-30">
        <div class="transactionSinglePage__title">
          {{ $t("sidebarExpertsBtn") }}
        </div>
        <div
          class="transactionSinglePage__value transactionSinglePage__value-think"
        >
          <div>
            {{
              `${transactionInformation.expert_first_name} ${transactionInformation.expert_last_name}`
            }}
          </div>
          <div>{{ transactionInformation.expert_code }}</div>
        </div>
      </div>
      <div class="col-lg-3 mt-30">
        <div class="transactionSinglePage__title">{{ $t("expertCoast") }}</div>
        <div class="transactionSinglePage__value">
          €{{ transactionInformation.expert_income }}
        </div>
      </div>
      <div class="col-lg-3 mt-30">
        <div class="transactionSinglePage__title">
          {{ $t("employeeCalendarService") }}
        </div>
        <div
          class="transactionSinglePage__value transactionSinglePage__value-think"
        >
          <div>
            {{ transactionInformation.service_name }}
          </div>
          <div>{{ $t("Location") }}: Luxembourg</div>
        </div>
      </div>
      <div class="col-lg-3 mt-30">
        <div class="transactionSinglePage__title">{{ $t("serviceType") }}</div>
        <div
          class="transactionSinglePage__value transactionSinglePage__value-think"
        >
          <div>
            {{ transactionInformation.consultation_name }}
          </div>
          <div>
            {{ $t("duration") }}:
            <duration-time
              :data="transactionInformation.consultation_duration"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 mt-10">
        <div class="transactionSinglePage__title">{{ $t("Company") }}</div>
        <div
          class="transactionSinglePage__value transactionSinglePage__value-think"
        >
          <div>{{ transactionInformation.company_name }}</div>
          <div>{{ transactionInformation.company_code }}</div>
        </div>
      </div>
      <div class="col-lg-3 mt-10">
        <div class="transactionSinglePage__title">
          {{ $t("companyCoverage") }}
        </div>
        <div class="transactionSinglePage__value">
          €{{ transactionInformation.company_coverage }}
        </div>
      </div>
      <div class="col-lg-3 mt-10">
        <div class="transactionSinglePage__title">{{ $t("contactInfo") }}</div>
        <div
          class="transactionSinglePage__value transactionSinglePage__value-think"
        >
          <div>
            {{
              `${transactionInformation.company_contact_first_name} ${transactionInformation.company_contact_last_name}`
            }}
          </div>
          <div>
            {{ transactionInformation.company_contact_email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ShortName from "@/components/components-helpers/ShortName";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import DurationTime from "@/components/components-helpers/DurationTime";

export default {
  name: "TransactionAdminSinglePage",
  components: {
    DurationTime,
    DataFormatted,
    ShortName,
    BaseButton,
    HorizontalArrow,
    MainLayout,
  },
  data() {
    return {
      transactionInformation: [],
    };
  },
  async mounted() {
    this.transactionInformation = await this.getTransactionInformation();
  },
  methods: {
    async getTransactionInformation() {
      return this.$http
        .get("/appointment-transactions/" + this.$route.params.transaction)
        .then(({ data }) => {
          return data.body;
        });
    },
  },
};
</script>

<style scoped></style>
