<template>
  <div class="d-flex align-items-center">
    <base-button @click="$router.back()" :back="true">
      <horizontal-arrow />
    </base-button>
    <h1 class="m-0 ms-3">{{ transaction.service_name }}</h1>
  </div>
  <div class="transactionEmployeeSinglePage">
    <div class="row">
      <div class="col-6">
        <div class="mb-30">
          <div class="transactionEmployeeSinglePage__title">Expert</div>
          <div class="transactionEmployeeSinglePage__text">
            <short-name
              :name="`${transaction.expert_first_name} ${transaction.expert_last_name}`"
            />
          </div>
          <div class="transactionEmployeeSinglePage__text">
            {{ transaction.expert_position }}
          </div>
        </div>
        <div class="mb-30">
          <div class="transactionEmployeeSinglePage__title">Billing date</div>
          <div class="transactionEmployeeSinglePage__text weight-400">
            <data-formatted
              date-format="HH:mm"
              :data="
                transaction.payment_date
                  ? transaction.payment_date
                  : transaction.invoice_sending_date
              "
            />
          </div>
          <div class="transactionEmployeeSinglePage__text">
            <data-formatted
              date-format="dd MMM yyyy"
              :data="
                transaction.payment_date
                  ? transaction.payment_date
                  : transaction.invoice_sending_date
              "
            />
          </div>
        </div>
        <div class="mb-30">
          <div class="transactionEmployeeSinglePage__title">Payment method</div>
          <div class="transactionEmployeeSinglePage__text">Card ending:</div>
          <div class="transactionEmployeeSinglePage__text">
            {{
              transaction.payment_method
                ? ` **** **** **** ${transaction.payment_method}`
                : " "
            }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-30">
          <div class="transactionEmployeeSinglePage__title">Service</div>
          <div class="transactionEmployeeSinglePage__text">
            {{ transaction.service_name }}
          </div>
          <div class="transactionEmployeeSinglePage__text">
            {{ transaction.consultation_name }}
          </div>
        </div>
        <div class="mb-30">
          <div class="transactionEmployeeSinglePage__title">Booking date</div>
          <div class="transactionEmployeeSinglePage__text weight-400">
            <data-formatted
              date-format="HH:mm"
              :data="transaction.order_date"
            />
          </div>
          <div class="transactionEmployeeSinglePage__text">
            <data-formatted
              date-format="dd MMM yyyy"
              :data="transaction.order_date"
            />
          </div>
        </div>
        <div class="mb-30">
          <div class="transactionEmployeeSinglePage__title">Status</div>
          <div
            class="recentAppointmentsEmployeeTable__status"
            :class="{
              'recentAppointmentsEmployeeTable__status-fail': statusState(
                transaction.status
              ),
            }"
          >
            {{ transaction.status }}
          </div>
        </div>
      </div>
    </div>
    <div class="transactionEmployeeSinglePage__footer">
      €{{ transaction.price }}
    </div>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ShortName from "@/components/components-helpers/ShortName";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  name: "TransactionEmployeeSinglePage",
  components: {
    DataFormatted,
    ShortName,
    BaseButton,
    HorizontalArrow,
    MainLayout,
  },
  data() {
    return {
      transaction: {},
    };
  },
  mounted() {
    this.getTransactionInformation();
  },
  methods: {
    getTransactionInformation() {
      this.$http
        .get(
          `appointment-transactions/${this.$route.params.transaction}/employee`
        )
        .then(({ data }) => {
          this.transaction = data.body;
        });
    },
    statusState(status) {
      return status === "cancelled" || status === "failed";
    },
  },
};
</script>

<style></style>
