<template>
  <main-layout>
    <transaction-admin-single-page v-if="loginUser.role_id === 1" />
    <transaction-employee-single-page v-if="loginUser.role_id === 4" />
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import { mapGetters } from "vuex";
import TransactionAdminSinglePage from "@/components/pages-templates/transactions/TransactionAdminSinglePage";
import TransactionEmployeeSinglePage from "@/components/pages-templates/transactions/TransactionEmployeeSinglePage";

export default {
  name: "TransactionSinglePage",
  components: {
    TransactionEmployeeSinglePage,
    TransactionAdminSinglePage,
    MainLayout,
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
